import { Content, Controller, ControllerSettings, EventListener, ControllerHooks } from '@karrot/core';

import { State } from '@karrot/common';

export type TabsLinksSettings = {

};

@Controller({
    name: 'tabs-links',
    settings: {
    },
})
export class TabsLinksController {

    @Content('tab-link')
    public links: HTMLElement[];

    @Content('tab', 'global')
    public tabs: HTMLElement[];

    constructor(private element: HTMLElement,
                private settings: ControllerSettings<TabsLinksSettings>,
                private hooks: ControllerHooks) {
        //
    }

    public onInit(): void {
        State.set([this.links[0], this.tabs[0]], 'active');
    }

    @EventListener('click', 'links')
    public tabLinkClick(e: Event, link: HTMLElement): void {
        e.preventDefault();
        this.hooks.executeAction('tabChanged');

        this.inactiveAll();
        let targetString = link.getAttribute('href');

        if (!targetString) {
            return;
        }

        targetString = targetString.replace('#', '');

        const target = this.tabs.filter((t) => t.id === targetString)[0];

        if (!target) {
            return;
        }

        State.set([link, target], 'active', true);
    }

    private inactiveAll(): void {
        State.set([...this.links, ...this.tabs], 'active', false);
    }

}
