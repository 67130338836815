
import { CookiesManager, FormController, ModalController, ScrollToController } from "@karrot/common";
import { ControllerSettings, Karrot } from "@karrot/core";

import { AnimationController } from "./controllers/animation.controller";
import { AppController } from "./controllers/app.controller";
import { HeaderSlideController } from "./controllers/header-slide.controller";
import { HeaderSliderController } from "./controllers/header-slider.controller";
import { MenuTogglerController } from "./controllers/menu-toggler.controller";

import { CookiesPolicyController } from "./controllers/cookies-policy.controller";
import { MapController } from "./controllers/map.controller";
import { TabsLinksController } from "./controllers/tabs-links.controller";

Karrot({
	controllers: [
		AppController,
		ScrollToController,
		HeaderSliderController,
		HeaderSlideController,
		MenuTogglerController,
		AnimationController,
		MapController,
		TabsLinksController,
		CookiesPolicyController,
	],
	services: [CookiesManager],
});
