import { Controller, ControllerSettings } from '@karrot/core';

declare var markerIcon: string;

export type MapSettings = {
    lat: number;
    lng: number;
    styles: google.maps.MapTypeStyle[];
};

@Controller({
    name: 'map',
    settings: {
        // tslint:disable-next-line
        styles: [{ "featureType": "water", "elementType": "geometry.fill", "stylers": [{ "color": "#d3d3d3" }] }, { "featureType": "transit", "stylers": [{ "color": "#808080" }, { "visibility": "off" }] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "visibility": "on" }, { "color": "#b3b3b3" }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.local", "elementType": "geometry.fill", "stylers": [{ "visibility": "on" }, { "color": "#ffffff" }, { "weight": 1.8 }] }, { "featureType": "road.local", "elementType": "geometry.stroke", "stylers": [{ "color": "#d7d7d7" }] }, { "featureType": "poi", "elementType": "geometry.fill", "stylers": [{ "visibility": "on" }, { "color": "#ebebeb" }] }, { "featureType": "administrative", "elementType": "geometry", "stylers": [{ "color": "#a7a7a7" }] }, { "featureType": "road.arterial", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.arterial", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "landscape", "elementType": "geometry.fill", "stylers": [{ "visibility": "on" }, { "color": "#efefef" }] }, { "featureType": "road", "elementType": "labels.text.fill", "stylers": [{ "color": "#696969" }] }, { "featureType": "administrative", "elementType": "labels.text.fill", "stylers": [{ "visibility": "on" }, { "color": "#737373" }] }, { "featureType": "poi", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi", "elementType": "labels", "stylers": [{ "visibility": "off" }] }, { "featureType": "road.arterial", "elementType": "geometry.stroke", "stylers": [{ "color": "#d6d6d6" }] }, { "featureType": "road", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, {}, { "featureType": "poi", "elementType": "geometry.fill", "stylers": [{ "color": "#dadada" }] }],
    },
})
export class MapController {
    private map: google.maps.Map;
    private mapOptions: google.maps.MapOptions;

    constructor(private element: HTMLElement,
                private settings: ControllerSettings<MapSettings>) {
        //
    }

    public onInit(): void {
        this.mapOptions = {
            center: {lat: this.settings.get('lat'), lng: this.settings.get('lng')},
            scrollwheel: false,
            styles: this.settings.get('styles'),
            zoom: 15,
        };

        this.map = new google.maps.Map(this.element, this.mapOptions);

        const marker = new google.maps.Marker({
            icon: markerIcon,
            map: this.map,
            position: {lat: this.settings.get('lat'), lng: this.settings.get('lng')},
            title: 'asd',
        });
    }

}
