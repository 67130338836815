import { State } from '@karrot/common';
import {  Controller, EventListener } from '@karrot/core';

@Controller({
    name: 'menu-toggler',
})
export class MenuTogglerController {
    public target: HTMLElement;

    constructor(private element: HTMLElement) {
    }

    public onInit(): void {
        const targetString = this.element.getAttribute('href');

        if (!targetString || targetString === '#') {
            return;
        }

        this.target = document.querySelector(targetString);
    }

    @EventListener('click')
    public clickEvent(e: Event): void {
        e.preventDefault();

        State.toggle([this.target, this.element], 'active');
    }

}
