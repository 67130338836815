import { CookiesManager } from '@karrot/common';
import { Content, Controller, EventListener } from '@karrot/core';

declare var cookiesText: string;
declare var cookiesButton: string;

@Controller({
    name: 'cookies-policy',
})
export class CookiesPolicyController {
    constructor(private cookies: CookiesManager, private element: HTMLElement) {
        console.log('asd');
    }

    public onInit(): void {
        //
        if (this.cookies.get('cookies-policy') === 'true') {
            return;
        }

        this.element.innerHTML = `
            <div class="c-cookies">
                <div>
                    <p class="c-cookies__text">${cookiesText}</p>
                </div>
                <div>
                    <a href="#" class="c-cookies__button">${cookiesButton}<i class="fas fa-times"></i></a>
                </div>
            </div>
        `;

        const cookiesButtonElement = this.element.querySelector('.c-cookies__button');

        if (cookiesButtonElement) {
            cookiesButtonElement.addEventListener('click', (e) => {
                e.preventDefault();

                this.cookies.set('cookies-policy', 'true', 30);
                this.element.innerHTML = '';
            });
        }
    }
}
