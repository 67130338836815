import { Action, Content, Controller, ControllerHooks, EventListener } from '@karrot/core';
import { TabsLinksController } from './tabs-links.controller';

// tslint:disable-next-line
declare var jQuery: any;
// tslint:disable-next-line
declare var lightGallery: any;

@Controller({
    name: 'app',
})
export class AppController {

    @Content('facts-slider')
    public factsSlider: HTMLElement;

    @Content('products-slider')
    public productsSlider: HTMLElement;

    @Content()
    public homeNav: HTMLElement;

    @Content()
    public appreciateList: HTMLElement;

    @Content()
    public gallery: HTMLElement;

    @Content('video-container')
    public videoContainers: HTMLElement[];

    @Content('videos-tabs-links')
    public videosLinks: TabsLinksController;

    constructor(private hooks: ControllerHooks) { }

    public onInit(): void {
        jQuery(this.factsSlider).slick({
            autoplay: true,
            autoplaySpeed: 5000,
            pauseOnFocus: false,
            pauseOnHover: false,
        });

        jQuery(this.appreciateList).slick({
            arrows: false,
            autoplay: true,
            autoplaySpeed: 2000,
            fade: true,
            pauseOnFocus: false,
            pauseOnHover: false,
        });

        jQuery(this.productsSlider).slick({
            autoplay: true,
            autoplaySpeed: 5000,
            pauseOnFocus: false,
            pauseOnHover: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                    },
                },

            ],
            slidesToShow: 3,

        });

        const selects = document.querySelector('select');

        jQuery(selects).selectric();

        if (this.gallery) {
            lightGallery(this.gallery);
        }

        console.log(this.videoContainers);
        if (this.videoContainers) {
            for (const videoContainer of this.videoContainers) {
                videoContainer.classList.add('is-paused');
            }
        }
    }

    @EventListener('scroll', 'window')
    public async onScroll(): Promise<void> {
        if (!this.homeNav) {
            return;
        }

        if (window.scrollY > window.innerHeight) {
            this.homeNav.classList.remove('p-nav--home');
        } else {
            this.homeNav.classList.add('p-nav--home');
        }
    }

    @EventListener('click', 'videoContainers')
    public onVideoClick(videoContainer: HTMLVideoElement): void {
        const video = videoContainer.querySelector('video') as HTMLVideoElement;

        console.log('video', video);

        if (video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2) {
            videoContainer.classList.add('is-paused');
            video.pause();
        } else {
            videoContainer.classList.remove('is-paused');
            video.play();
        }

    }

    @Action('tabChanged', 'videosLinks')
    public onVideoTabChanged(): void {
        for (const videoContainer of this.videoContainers) {
            const video = videoContainer.querySelector('video') as HTMLVideoElement;
            video.pause();
            videoContainer.classList.add('is-paused');
        }
    }

}
