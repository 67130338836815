import { State } from '@karrot/common';
import { Content, Controller, EventListener } from '@karrot/core';

@Controller({
    name: 'header-slide',
})
export class HeaderSlideController {

    @Content()
    public title: HTMLElement;

    @Content()
    public signature: HTMLElement;

    @Content()
    public image: HTMLElement;

    @Content()
    public background: HTMLElement;

    constructor(private element: HTMLElement) {
    }

    public onInit(): void {
        //
    }

    public showSlide(show: boolean = true): void {
        const slideElement = this.element;

        State.set(slideElement, 'active', show);

        setTimeout(() => {
            State.set(this.title, 'active', show);
        }, 400);

        setTimeout(() => {
            State.set(this.image, 'active', show);
        }, 900);

        setTimeout(() => {
            State.set(this.signature, 'active', show);
        }, 1200);
    }

    @EventListener('mousemove', 'body')
    public mouseMove(event: MouseEvent): void {
        const els = [this.signature, this.image.children[0] as HTMLElement, this.title, this.background];

        const x = event.clientX - (window.innerWidth / 2),
            y = event.clientY - (window.innerHeight / 2);

        for (const element of els) {
            if (!element) {
                continue;
            }

            const top = element.offsetTop;
            const left = element.offsetLeft;

            const speed = element.dataset.speed || '10';

            element.style.left = ((left - x) * (.001 * parseFloat(speed))).toFixed(2) + 'px';
            // element.style.top = ((top - y) * (.001 * parseFloat(speed))).toFixed(2)  + 'px';
        }

    }
}
