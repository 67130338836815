import { Content, Controller, EventListener } from '@karrot/core';
import { HeaderSlideController } from './header-slide.controller';

// tslint:disable-next-line
declare var jQuery: any;

@Controller({
    name: 'header-slider',
})
export class HeaderSliderController {
    @Content('header-slide')
    public slides: HeaderSlideController[];

    constructor(private element: HTMLElement) {
    }

    public onInit(): void {

        jQuery(this.element).on('beforeChange', (event, slick, slideId, nextSlideId) => {
            this.getSlide(slideId).showSlide(false);
            this.getSlide(nextSlideId).showSlide();
        });

        jQuery(this.element).on('init', () => {
            this.getSlide(0).showSlide();
        });

        jQuery(this.element).slick({
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
            fade: true,
            pauseOnFocus: false,
            pauseOnHover: false,
        });

    }

    private getSlide(slideId: number): HeaderSlideController {
        return this.slides[slideId];
    }

}
