import { Content, Controller, ControllerSettings, EventListener } from '@karrot/core';

// tslint:disable-next-line
declare var jQuery: any;

export type AnimationSettings = {
    delay: number;
    offset: number;
};

@Controller({
    name: 'animation',
    settings: {
        delay: 500,
        offset: 300,
    },
})
export class AnimationController {

    @Content('animation-child')
    public children: HTMLElement[];

    public isActivated: boolean = false;

    private offset: { top: number, left: number };

    constructor(private element: HTMLElement,
                private settings: ControllerSettings<AnimationSettings>) {
    }

    public onInit(): void {
        if (!this.children || this.children.length === 0) {
            this.children = [this.element];
        }

        for (const child of this.children) {
            child.classList.add('k-animation');
        }

        this.setOffset();
        this.onScroll();
    }

    @EventListener('scroll', 'window')
    public async onScroll(): Promise<void> {

        if (this.isActivated) {
            return;
        }
        const scrollY = window.scrollY + window.innerHeight;
        const offset = this.offset.top + this.settings.get('offset');

        if (offset > scrollY) {
            return;
        }

        for (const child of this.children) {
            const delay = parseInt(child.dataset.delay, 0) || this.settings.get('delay');
            await new Promise((r) => {
                setTimeout(() => {
                    r();
                }, delay);
            });

            child.classList.add('is-active');
        }

        this.isActivated = true;
    }

    public setOffset(): { top: number, left: number } {
        let parent = this.element;

        const offset = {
            left: 0,
            top: 0,
        };

        while (parent.offsetParent) {
            offset.top += parent.offsetTop;
            offset.left += parent.offsetLeft;
            parent = parent.offsetParent as HTMLElement;
        }

        this.offset = offset;

        return this.offset;
    }

}
